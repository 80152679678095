<script>
	import { Router, Route } from "svelte-routing";
	import Home from "./Home.svelte";
	import About from "./About.svelte";
	import Editor from "./Editor.svelte";
	export let url = "";
</script>

 <Router url="{url}">
	<Route path="/" component="{Home}" />
	<Route path="/about" component="{About}" />
	<Route path="/pdf-editor" component="{Editor}" />
  </Router>


