<script>
  import Tailwind from "./Tailwind.svelte";
  import Navbar from "./widgets/Navi.svelte";
  import Footer from "./widgets/Footer.svelte";

</script>
<Tailwind />
<header class="fixed w-full z-50">
	<Navbar/>
</header>
<main id="main">
    <section class="bg-white dark:bg-gray-900">
        <div class="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:pt-32">
            <div class="mr-auto place-self-center">
                <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">Online PDF Editing Add Images, Text, and Drawings</h1>
                <p class="mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Transform your PDF files effortlessly by adding images, text, and hand-drawn objects directly within your web browser. Our user-friendly interface ensures a seamless experience, eliminating the need for complex software installations.</p>
            </div>
        </div>
    </section>

	<section class="bg-gray-50 dark:bg-gray-800">
		<div class="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:py-24 lg:px-6">
			<!-- Row -->
			<h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">About Online PDF Editor: Your Trusted Browser-Based Solution</h2>
			<div class="items-start gap-8 lg:grid lg:grid-cols-2 mt-8">
				<div class="text-gray-500 sm:text-lg dark:text-gray-400">
					<p class="mb-8 font-light lg:text-xl">Welcome to Online PDF Editor, where document transformation happens effortlessly within your browser. At PDF Editor, we take pride in offering a powerful, user-friendly platform that redefines the way you edit PDFs. Here's a glimpse into what makes our tool your go-to choice for seamless PDF modifications.</p>
					<p class="mb-2 dark:text-gray-400 font-light">
						<span class="font-bold">Innovation in Your Browser:</span><br>
 Online PDF Editor is more than just a tool; it's a revolution in document editing. No need for downloads or installations – experience the full capabilities of our editor directly in your browser. We've simplified the process, ensuring you can edit PDFs with ease wherever you are.
					</p>
					<p class="mb-2 dark:text-gray-400 font-light">
						<span class="font-bold">Effortless Editing Features:</span><br>
Unlock a world of possibilities with our intuitive features. Whether you're adding images to enhance visual appeal, inserting text for clarity, or drawing objects to emphasize key points – our platform empowers you to make edits effortlessly.
					</p>
					<p class="mb-2  dark:text-gray-400 font-light">
						<span class="font-bold">Security and Privacy:</span><br>
Rest easy knowing that your documents are in safe hands. This Online PDF Editor do not store personal data, do not send files to the server - everything happens on your browser!
					</p>
				</div>
                <div class="text-gray-500 sm:text-lg dark:text-gray-400">
					<p class="mb-8 font-light lg:text-xl">Discover the simplicity and power of our Online PDF Editor. Whether you're a professional, student, or entrepreneur, our tool is designed to cater to your diverse editing needs. Experience the future of PDF editing</p>
                    <!-- List -->
                    <ul role="list" class="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                        <li class="flex space-x-3">
                            <!-- Icon -->
                            <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                            <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">Convenience</span>
                        </li>
                        <li class="flex space-x-3">
                            <!-- Icon -->
                            <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                            <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">User-Friendly Interface</span>
                        </li>
                        <li class="flex space-x-3">
                            <!-- Icon -->
                            <svg class="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                            <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">No server, software, No Fuss</span>
                        </li>
                        
                    </ul>
                </div>
			</div>
		</div>
	</section>
<Footer/>
</main>