<script>
    import { Link } from "svelte-routing";

	const currentYear = new Date().getFullYear();
	
</script>
	<footer class="bg-white dark:bg-gray-800">
        <div class="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">

            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8">
            <div class="text-center">
                <Link to="" class="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white">
                   <img src="./logo.svg" class="h-8 mr-3 sm:h-9" alt="PDF Editor Logo" />
				</Link>
                <span class="block text-sm text-center text-gray-500 dark:text-gray-400">© {currentYear} PDFEditor™.
                </span>

            </div>
        </div>
		
    </footer>