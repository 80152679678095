<script>
	import Tailwind from "./Tailwind.svelte";
	import Navbar from "./widgets/Navi.svelte";
	import Footer from "./widgets/Footer.svelte";
	import {
		Link
	} from "svelte-routing";
</script>

<Tailwind />
<header class="fixed w-full z-50">
	<Navbar />
</header>
<main id="main">
	<section class="bg-white dark:bg-gray-900">
		<div class="grid max-w-screen-xl px-4 pt-20 mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 lg:pt-32">
			<div class="mr-auto place-self-center lg:col-span-7">
				<h1
					class="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
					Online PDF Editing <br>Add Images, Text, and Drawings</h1>
				<p
					class="max-w-2xl mb-6 mt-8 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
					Transform your PDF files effortlessly by adding images, text, and hand-drawn objects directly within
					your web browser. Our user-friendly interface ensures a seamless experience, eliminating the need
					for complex software installations.</p>
				<div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
					<Link to="pdf-editor"
						class="inline-flex items-center justify-center w-full px-5 py-3 text-sm text-purple-700 font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-auto hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
					<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg"
						class="mr-2">
						<path
							d="M0 2.875C0 1.28926 1.27298 0 2.83871 0H9.93548V5.75C9.93548 6.54512 10.5698 7.1875 11.3548 7.1875H17.0323V13.6562H7.80645C6.24073 13.6562 4.96774 14.9455 4.96774 16.5312V23H2.83871C1.27298 23 0 21.7107 0 20.125V2.875ZM17.0323 5.75H11.3548V0L17.0323 5.75ZM7.80645 15.8125H9.22581C10.5964 15.8125 11.7097 16.94 11.7097 18.3281C11.7097 19.7162 10.5964 20.8438 9.22581 20.8438H8.51613V22.2812C8.51613 22.6766 8.19677 23 7.80645 23C7.41613 23 7.09677 22.6766 7.09677 22.2812V16.5312C7.09677 16.1359 7.41613 15.8125 7.80645 15.8125ZM9.22581 19.4062C9.81573 19.4062 10.2903 18.9256 10.2903 18.3281C10.2903 17.7307 9.81573 17.25 9.22581 17.25H8.51613V19.4062H9.22581ZM13.4839 15.8125H14.9032C16.0786 15.8125 17.0323 16.7783 17.0323 17.9688V20.8438C17.0323 22.0342 16.0786 23 14.9032 23H13.4839C13.0935 23 12.7742 22.6766 12.7742 22.2812V16.5312C12.7742 16.1359 13.0935 15.8125 13.4839 15.8125ZM14.9032 21.5625C15.2935 21.5625 15.6129 21.2391 15.6129 20.8438V17.9688C15.6129 17.5734 15.2935 17.25 14.9032 17.25H14.1935V21.5625H14.9032ZM18.4516 16.5312C18.4516 16.1359 18.771 15.8125 19.1613 15.8125H21.2903C21.6806 15.8125 22 16.1359 22 16.5312C22 16.9266 21.6806 17.25 21.2903 17.25H19.871V18.6875H21.2903C21.6806 18.6875 22 19.0109 22 19.4062C22 19.8016 21.6806 20.125 21.2903 20.125H19.871V22.2812C19.871 22.6766 19.5516 23 19.1613 23C18.771 23 18.4516 22.6766 18.4516 22.2812V19.4062V16.5312Z"
							fill="black" />
					</svg>

					Edit your PDF
					</Link>
				</div>
			</div>
			<div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
				<img src="./pdf-editor-hero-image.jpg" alt="hero image">
			</div>
		</div>
	</section>

	<section class="bg-gray-50 dark:bg-gray-800">
		<div class="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
			<!-- Row -->
			<div class="items-start gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
				<div class="text-gray-500 sm:text-lg dark:text-gray-400">
					<h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">Revolutionize
						PDF Editing with Online Tool</h2>
					<p class="mb-8 font-light lg:text-xl">Discover a seamless online PDF editing experience. Tool lets
						you add images, text, and hand-drawn elements directly in your browser. No uploads, no fuss –
						just effortless PDF customization.</p>
					<p class="mb-2 dark:text-gray-400 font-light">
						<span class="font-bold">Add Images:</span><br>
						Transform your PDFs by seamlessly incorporating images directly in your browser. Personalize
						documents, presentations, and more effortlessly.
					</p>
					<p class="mb-2 dark:text-gray-400 font-light">
						<span class="font-bold">Insert Text:</span><br>
						Bid farewell to cumbersome PDF edits. Our intuitive text tool allows you to insert and customize
						text swiftly, making editing a breeze.
					</p>
					<p class="mb-2  dark:text-gray-400 font-light">
						<span class="font-bold">Draw and Annotate:</span><br>
						Express your creativity or emphasize key points by drawing and annotating directly on your PDF.
						Customize your documents with hand-drawn objects effortlessly.
					</p>
				</div>
				<div class="text-gray-500 sm:text-lg dark:text-gray-400 mt-16">
					<p class="mb-2 dark:text-gray-400 font-light">
						<span class="font-bold">Upload Your PDF:</span><br>
						Begin by uploading your PDF directly to our online editor. No installations required – start editing instantly.
					</p>
					<p class="mb-2 dark:text-gray-400 font-light">
						<span class="font-bold">Add Your Elements:</span><br>
						Insert images, type in text, or draw and annotate directly on your PDF. Our user-friendly interface ensures a smooth editing experience.
					</p>
					<p class="mb-2 dark:text-gray-400 font-light">
						<span class="font-bold">Save and Download:</span><br>
						Once satisfied with your edits, save and download the perfected document. No stress, no complications – just a simplified PDF editing process.
					</p>
					
				</div>
			</div>
		</div>
	</section>
    <section class="bg-gray-100 dark:bg-gray-800">
        <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
            <figure class="max-w-screen-md mx-auto">
                <svg class="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                </svg> 
                <blockquote>
                    <p class="text-xl font-medium text-gray-900 md:text-2xl dark:text-white">"Discovering the online PDF editor has been a game-changer for me! As a professional who frequently works with documents, I needed a reliable and efficient tool that could seamlessly edit PDFs without the hassle of downloading any software. This online PDF editor not only met but exceeded my expectations."</p>
                </blockquote>
                <figcaption class="flex items-center justify-center mt-6 space-x-3">
                    <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <div class="pr-3 font-medium text-gray-900 dark:text-white">John</div>
                    </div>
                </figcaption>
            </figure>
        </div>
      </section>
	<section class="bg-white dark:bg-gray-900">
		<div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
			<div class="mx-auto mb-8 lg:mb-12">
				<h2 class="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">Edit PDFs anytime, anywhere – all you need is an internet connection</h2>
				<p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Discover a seamless online PDF
					editing experience. Tool lets you add images, text, and hand-drawn elements directly in your
					browser. No uploads, no fuss – just effortless PDF customization.</p>
				<p class="mb-5 font-bold text-gray-500 sm:text-xl dark:text-gray-400">Enhance Your PDFs Effortlessly</p>
				<p class="mb-2 text-gray-500 dark:text-gray-400">Add Images:<br>
					Transform your PDFs by seamlessly incorporating images directly in your browser. Personalize
					documents, presentations, and more effortlessly.
				</p>
				<p class="mb-2 text-gray-500 dark:text-gray-400">
					Insert Text:<br>
					Bid farewell to cumbersome PDF edits. Our intuitive text tool allows you to insert and customize
					text swiftly, making editing a breeze.
				</p>
				<p class="mb-2 text-gray-500 dark:text-gray-400">
					Draw and Annotate:<br>
					Express your creativity or emphasize key points by drawing and annotating directly on your PDF.
					Customize your documents with hand-drawn objects effortlessly.
				</p>
			</div>
		</div>
	</section>
<Footer/>
</main>